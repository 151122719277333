import React, {useState} from "react";
import VisibilitySensor from "react-visibility-sensor";

const RenderWhenVisible = ({children}) => {
  return (
    <VisibilitySensor scrollCheck={true}
                      resizeCheck={true}
                      partialVisibility={true}>
      {({isVisible}) =>
        isVisible ? children : <div className='place-holder'>&nbsp;</div>
      }
    </VisibilitySensor>
  );
};

export default RenderWhenVisible;