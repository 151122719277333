import React from 'react';
import styled from 'styled-components';
import Sunburst from 'react-vis/es/sunburst';
import LabelSeries from 'react-vis/es/plot/series/label-series';
import {MAIN_BLUE, PAGE_FG_COLOR} from "../styles/colors";
import { capitalize } from "../utils/misc";
import { MACRONUTRIENTS_SIZE } from "../styles/dimens";
import Hand from '../../images/inline/hand.svg'

const HAND_SIZE = 48;

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1px;
  & > div {
    margin: auto;
  }
  
  .hand {
    opacity: 0.5;
    width: ${HAND_SIZE}px;
    height: ${HAND_SIZE}px;
    z-index: 555;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const LABEL_STYLE = {
  fontSize: '14px',
  fill: PAGE_FG_COLOR,
  textAnchor: 'middle'
};

/**
 * Recursively work backwards from highlighted node to find path of valud nodes
 * @param {Object} node - the current node being considered
 * @returns {Array} an array of strings describing the key route to the current node
 */
function getKeyPath(node) {
  if (!node.parent) {
    return ['root'];
  }

  return [(node.data && node.data.name) || node.name].concat(
    getKeyPath(node.parent)
  );
}

/**
 * Recursively modify data depending on whether or not each cell has been selected by the hover/highlight
 * @param {Object} data - the current node being considered
 * @param {Object|Boolean} keyPath - a map of keys that are in the highlight path
 * if this is false then all nodes are marked as selected
 * @returns {Object} Updated tree structure
 */
function updateData(data, keyPath) {
  if (data.children) {
    data.children.map(child => updateData(child, keyPath));
  }
  data.style = {
    ...data.style,
    fillOpacity: keyPath && !keyPath[data.name] ? 0.2 : 1
  };
  return data;
}

const COLORS = {
  'fat': MAIN_BLUE,
  'saturated fat': "#6CF",
  'unsaturated fat': "#9CF",
  'trans fat': "#CCF",
  'carbs': '#963',
  'fiber': '#696',
  'total sugars': '#F30',
  'starches': '#C93',
  'protein': '#FC0'
};

const nutrientToJson = n => ({
  name: capitalize(n.name),
  hex: COLORS[n.name],
  value: n.childNutrients && n.childNutrients.length > 0 ? null : n.amount,
  children: (n.childNutrients || []).map(nutrientToJson)
});

const findNutrient = (ns, name) =>
    ns.find(n => n.name.toLowerCase() === name.toLowerCase());

const findNutrientIndex = (ns, name) =>
    ns.findIndex(n => n.name.toLowerCase() === name.toLowerCase());

const getNutrientAmount = (ns, name) => {
  const n = findNutrient(ns, name);
  return n ? n.amount : 0
};

const addStarch = nutrients => {
  const carbs = findNutrient(nutrients, 'carbs');
  const carbsAmount = carbs.amount;
  const fiberAmount = getNutrientAmount(carbs.childNutrients, 'fiber');
  const sugarAmount = getNutrientAmount(carbs.childNutrients, 'sugar');
  const carbsIndex = findNutrientIndex(nutrients, carbs.name);
  return [
    ...nutrients.slice(0, carbsIndex),
    {
      ...carbs,
      childNutrients: [
        ...carbs.childNutrients,
        { name: 'starches', amount: carbsAmount - (fiberAmount + sugarAmount) }
      ]
    },
    ...nutrients.slice(carbsIndex + 1)
  ];
};

const combineUnsaturatedFats = nutrients => {
  const fat = findNutrient(nutrients, 'fat');
  const monoAmount = getNutrientAmount(fat.childNutrients, 'monounsaturated fat');
  const polyAmount = getNutrientAmount(fat.childNutrients, 'polyunsaturated fat');
  const fatIndex = findNutrientIndex(nutrients, fat.name);
  const monoIndex = findNutrientIndex(fat.childNutrients, 'monounsaturated fat');
  const polyIndex = findNutrientIndex(fat.childNutrients, 'polyunsaturated fat');
  const i1 = Math.min(monoIndex, polyIndex);
  const i2 = Math.max(monoIndex, polyIndex);
  return [
    ...nutrients.slice(0, fatIndex),
    {
      ...fat,
      childNutrients: [
        ...fat.childNutrients.slice(0, i1),
        ...fat.childNutrients.slice(i1 + 1, i2),
        ...fat.childNutrients.slice(i2 + 1),
        { name: 'unsaturated fat', amount: monoAmount + polyAmount}
      ]
    },
    ...nutrients.slice(fatIndex + 1)
  ]
};

const deriveNutrients = ns => combineUnsaturatedFats(addStarch(ns));

class MacronutrientBreakdown extends React.PureComponent {
  constructor(props) {
    super(props);
    const keys = Object.keys(COLORS);
    const includedNutrs = props.nutrients.filter(n => !n.hasParent);
    const nutrients = deriveNutrients(includedNutrs);
    const macronutrients = nutrients.filter(n => n.macro);
    const data = {
      children: macronutrients.map(nutrientToJson)
    };
    this.state = {
      data: updateData(data, false),
      finalValue: false,
      clicked: false
    }
  }

  render() {
    const {clicked, data, finalValue} = this.state;
    return (
      <Container className="basic-sunburst-example-wrapper">
        <Sunburst
          animation
          className="basic-sunburst-example"
          hideRootNode
          onValueMouseOver={node => {
            if (clicked) {
              return;
            }
            const path = getKeyPath(node).reverse();
            const pathAsMap = path.reduce((res, row) => {
              res[row] = true;
              return res;
            }, {});
            this.setState({
              finalValue: path[path.length - 1],
              data: updateData(data, pathAsMap)
            });
          }}
          onValueMouseOut={() =>
            clicked
              ? () => {}
              : this.setState({
                finalValue: false,
                data: updateData(data, false)
              })
          }
          onValueClick={() => this.setState({clicked: !clicked})}
          style={{
            stroke: '#ddd',
            strokeOpacity: 0.3,
            strokeWidth: '0.5'
          }}
          colorType="literal"
          getSize={d => d.value}
          getColor={d => d.hex}
          data={data}
          height={MACRONUTRIENTS_SIZE}
          width={MACRONUTRIENTS_SIZE}
        >
          {finalValue && (
            <LabelSeries
              data={[{x: 0, y: 12, label: finalValue, style: LABEL_STYLE}]}
            />
          )}
        </Sunburst>
        {finalValue ? null :
          <Hand className='hand' width={HAND_SIZE} height={HAND_SIZE} />}
      </Container>
    );
  }
}

export default MacronutrientBreakdown;
