import React from 'react';
import XYPlot from 'react-vis/es/plot/xy-plot';
import XAxis from 'react-vis/es/plot/axis/x-axis';
import VerticalBarSeries from 'react-vis/es/plot/series/vertical-bar-series';
import DiscreteColorLegend from 'react-vis/es/legends/discrete-color-legend';
import {OVERVIEW_PLOT_COLORS, PAGE_FG_COLOR} from "../styles/colors"
import styled, { createGlobalStyle } from "styled-components"
import {
  OVERVIEW_PLOTS_HEIGHT, OVERVIEW_PLOTS_INNER_WIDTH,
  OVERVIEW_PLOTS_OUTER_WIDTH,
  responsive
} from "../styles/dimens"
import {capitalize, transposeArray, zipmap} from "../utils/misc";

const GlobalStyles = createGlobalStyle`
  .rv-discrete-color-legend-item__color {
    height: 10px !important;
  }
  .rv-discrete-color-legend-item__title {
    margin-left: 3px;
    color: ${PAGE_FG_COLOR};
  }
  
  .rv-xy-plot__series.rv-xy-plot__series--bar {
    transform: translate(0, 0) !important;
    -webkit-transform: translate(0, 0) !important;
  }
  
  .rv-xy-plot__axis.rv-xy-plot__axis--horizontal {
      transform: translate(0, 280px) !important;
      -webkit-transform: translate(0, 280px) !important;
  }
`;

const Container = React.memo(styled.div`
  ${responsive({
    'phablet-desktop': `
      width: ${OVERVIEW_PLOTS_INNER_WIDTH}px;
      margin: 27px 37px 5px 35px;
      box-sizing: border-box:
    `,
    mobile: `
      width: ${OVERVIEW_PLOTS_INNER_WIDTH}px;
      padding: 0 5px;
      `
  })};
  position: relative;
  overflow: hidden;
`);

class BarChart extends React.PureComponent {
  constructor(props) {
    super(props);
    const {baseFood, recipes, fields} = props;
    const all = [baseFood, ...recipes]
    const nutrValuesByRecipe = all.map(r => r.nutrients
      .filter(n => fields.includes(n.name))
      .map(n => ({[n.name]: n.normAmount}))
    );

    const nvs = nutrValuesByRecipe.flatMap(nv => nv);
    const fieldValues = Object.fromEntries(fields.map(f =>
      [f, nvs.flatMap(nv =>
        Object.entries(nv)
          .filter(([k, v]) => f === k)
          .map(([k, v]) => v)
      )]
    ));

    const maxFieldValues = zipmap(
      Object.keys(fieldValues),
      Object.values(fieldValues).map(vs => Math.max(...vs))
    );

    const sortedFields = Object.entries(maxFieldValues)
      .sort(([k1, v1], [k2, v2]) => v2 - v1)
      .map(([k, v]) => k);

    const dataByField = sortedFields.map(f =>
      nvs.flatMap(nv =>
        Object.entries(nv)
          .filter(([k, v]) => f === k)
          .map(([k, v]) => ({
            x: k === 'total sugars' ? 'Sugar' : capitalize(k),
            y: v === 0 ? 0.0001 : v  // Draw small line instead of empty space
          }))
      )
    );

    const data = transposeArray(dataByField);
    this.state = { data, loaded: false };
  }

  componentDidMount() {
    this.setState({loaded: true});
  }

  render() {
    const {data, loaded} = this.state;
    const {baseFood, color} = this.props;
    const legend = [{
      title: 'Avg ' + baseFood.name,
      color: OVERVIEW_PLOT_COLORS[0]
    }, {
      title: 'These recipes',
      color: color
    }];
    if (loaded) {
      return (
        <Container>
          <GlobalStyles />
          <DiscreteColorLegend items={legend}
                               style={{position: 'absolute',
                                 fontSize: '12px',
                                 top: 2,
                                 right: 0,
                                 backgroundColor: 'rgba(252,252,252,0.5)',
                                 padding: 5,
                                 strokeWidth: 32}} />
          <XYPlot height={OVERVIEW_PLOTS_HEIGHT}
                  width={OVERVIEW_PLOTS_OUTER_WIDTH}
                  xType="ordinal">

            {data.map((d, i) => (
              <VerticalBarSeries
                key={i}
                data={d}
                color={i === 0 ? OVERVIEW_PLOT_COLORS[0] : color} />
            ))}
            <XAxis style={{fill: PAGE_FG_COLOR, fontSize: '12px'}} />
          </XYPlot>
        </Container>
      );
    } else {
      return null;
    }
  }
}

export default BarChart;
