
export const scaleUnitsFromMcg = (amountInMcg) => {
  if (amountInMcg === 0) {
    return null;
  } else {
    const order = Math.log10(amountInMcg);
    if (order < 3) {
      return `${Math.round(amountInMcg)}mcg`
    } else if (order < 6) {
      return `${Math.round(amountInMcg / 1000)}mg`
    } else if (order < 9) {
      return `${Math.round(amountInMcg / 1000000)}g`
    } else {
      return `${Math.round(amountInMcg / 1000000000)}kg`
    }
  }
}

export const scaleUnitsFromGrams = (amountInGrams) =>
  scaleUnitsFromMcg(amountInGrams * 1000000)

export const gramsToOz = (g) => `${Math.round(g / 28.3495)} oz`

export const calculateServingSize = (recipes, threshold) => {
  const recipeCalories = recipes.map(r => r.calories)
  const minCals = Math.min(...recipeCalories)
  const maxCals = Math.max(...recipeCalories)
  if (minCals > threshold) {
    // Cut all recipes to the same serving size
    const ratio = threshold / maxCals
    const mass = recipes.find(r => r.calories === maxCals).mass;
    return mass * ratio
  } else {
    return null
  }
}

// TODO: Get absolute amount of nutrient
export const getNutrientAmount = (recipe, name) =>
  recipe.nutrients.find(n => n.name === name);
