import React from "react";
import styled from "styled-components";
import {
  CONTENTS_BORDER_COLOR,
  HEADER_COLOR_CONTENTS,
} from "../styles/colors"
import RecipeThumb from "./RecipeThumb";
import ArticleSection from "./ArticleSection";
import listIcon from "../../images/icons/clipboard.svg";
import {responsive} from "../styles/dimens";

const ContentsComponent = React.memo(({recipes, recipeRefs, className}) => {
  const onClick = (i) => {
    const videoIds = recipes.map(r => r.videoId);
    recipeRefs[i].current.scrollIntoView({behavior: 'smooth'});
    window.location.hash = '/' + videoIds[i];
  };

  return (
    <ArticleSection icon={listIcon} title='Recipes'
                    headerColor={HEADER_COLOR_CONTENTS} className={className}
                    openByDefault={true}>
      <ul>
        {recipes.map((recipe, i) => (
            <li key={i}>
              <a onClick={() => onClick(i)} title={recipe.title}>
                <RecipeThumb recipe={recipe} className='thumb' />
                <div className='info'>
                  <div className='title'>{recipe.title}</div>
                  <div className='subtitle'>{recipe.channelTitle}</div>
                </div>
              </a>
            </li>
        ))}
      </ul>
    </ArticleSection>
  );
});

const Contents = styled(ContentsComponent)`
  width: 100%;
  ${responsive({
    mobile: `
      margin-top: 20px;
      margin-bottom: 20px;
      .content {
        padding-top: 5px;
      }
    `
  })};
  
  ul {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
  }
  
  li {
      padding: 8px 0;
  }
  
  li:not(:last-of-type) {
      border-bottom: thin solid ${CONTENTS_BORDER_COLOR};
  }
  
  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    cursor: pointer;
    
    img, span {
      -webkit-filter: grayscale(40%); /* Safari 6.0 - 9.0 */
      filter: grayscale(40%);
    }
    &:hover img, &:hover span {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
    
    .thumb {
      flex-shrink: 0;
      margin: auto;
    }
    
    .info {
      flex-grow: 1;
      padding-left: 10px;
      margin: auto;
      
      .title {
          font-weight: bold;
          padding-bottom: 5px
      }
    }
  }
`;

export default Contents;