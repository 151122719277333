import React from 'react';
import styled from 'styled-components';
import { SIDE_BAR_WIDTH } from '../styles/dimens';
import { PAGE_FG_COLOR } from '../styles/colors';

const Container = React.memo(styled.aside`
  width: ${SIDE_BAR_WIDTH};
  float: right;
  margin: 16px 0 0 20px;
  box-sizing: border-box;
  //border: thin solid #CCC;
`);

export default React.memo(({className}) => (
  <Container className={className}>

  </Container>
));
