import React, { PureComponent, useState } from "react";
import styled from 'styled-components';
import { ChevronLeft } from 'styled-icons/fa-solid/ChevronLeft';
import { ChevronRight } from 'styled-icons/fa-solid/ChevronRight';
import Recipe from "../components/Recipe";
import {
  MAX_MAIN_WIDTH, OVERVIEW_PLOTS_INNER_WIDTH,
  OVERVIEW_PLOTS_OUTER_WIDTH, PAGE_PADDING,
  responsive
} from "../styles/dimens"
import DesktopSideBar from "../components/DesktopSideBar";
import {createHistory, LocationProvider, Redirect} from "@reach/router";
import Helmet from 'react-helmet';
import {
  LargeScreenOrMatch, NotSmallScreenOnly
} from "../components/MediaQueries";
import Breadcrumbs from "../components/Breadcrumbs";
// import loadable from '@loadable/component';

// const OverviewVisualizations = loadable(
//   () => import("../components/OverviewVisualizations"));
import OverviewVisualizations from "../components/OverviewVisualizations";
import {
  HEADER_COLOR_INGREDIENT_COMPARISON,
  LINK_COLOR,
  MOBILE_RECIPE_NAV_BG_COLOR,
  MOBILE_RECIPE_NAV_BORDER_COLOR,
  OVERVIEW_PLOT_COLORS,
} from "../styles/colors"
import HideOnError from "../components/HideOnError"
import Summary from "../components/Summary";
import Contents from "../components/Contents";
import {capitalize} from "../utils/misc";
import ArticleSection from "../components/ArticleSection";
import listIcon from "../../images/icons/clipboard.svg";
import RecipeThumb from "../components/RecipeThumb";
import {SectionUnlessSmall} from "../components/Section";
import ArticlesList from "../components/ArticlesList";
import Alert from '@material-ui/lab/Alert';
import RenderWhenVisible from "../components/RenderWhenVisible";
import createHashSource from "../utils/createHashSource";
import {calculateServingSize} from "../utils/nutrs";
import {DOMAIN, MAXIMUM_CALORIES} from "../constants";

// if (process.env.NODE_ENV !== 'production') {
//   const {whyDidYouUpdate} = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

const source = createHashSource();
const history = createHistory(source);

const getPage = (recipes, hash) => {
  if (hash) {
    const videoIds = recipes.map(r => r.videoId);
    return videoIds.findIndex(c => hash.endsWith(c)) + 1;
  } else {
    return 0;
  }
};

const Lead = React.memo(styled(Summary)`
  margin-bottom: 12px;
  ${responsive({
    mobile: `
      padding: 0 21px;
    `,
    'phablet-desktop': `
      padding: 0 13px;
    `
  })}
  p:first-of-type {
      margin-block-start: 0
  }
`);

const TopWrapper = React.memo(styled.div`
  width: 100%;
  box-sizing: border-box;
  ${responsive({
    'phablet-desktop': `
      display: flex;
      justify-content: space-between;
    `
  })};
`);

const TopLeftWrapper = React.memo(styled.div`
  ${responsive({
    'phablet-desktop': `
      max-width: ${MAX_MAIN_WIDTH};
    `
  })};
`);

const MainWrapper = React.memo(styled.div`
  width: 100vw;
`);

const OverviewPlotsComponent =
    React.memo(({className, ...props}) => (
  <div className={className}>
    <SectionUnlessSmall>
      <HideOnError>
        <RenderWhenVisible>
          <OverviewVisualizations {...props} />
        </RenderWhenVisible>
      </HideOnError>
    </SectionUnlessSmall>
  </div>
));

const OverviewPlot = React.memo(styled(OverviewPlotsComponent)`
  ${responsive({
    'phablet-desktop': `
      margin: 10px 10px 15px 30px;
      width: ${OVERVIEW_PLOTS_OUTER_WIDTH + 20}px;
      box-sizing: border-box;
    `,
    mobile: `
      margin: 20px auto;
      .place-holder {
        width: ${OVERVIEW_PLOTS_INNER_WIDTH}px;
      }
    `
  })};
`);

const Main = React.memo(styled.main`
  ${responsive({ 
    'phablet-desktop': `
      max-width: ${MAX_MAIN_WIDTH}
    `,
     mobile: `
      width: 100%
      ` 
    })};
`);

const MobileNavBar = React.memo(styled.nav`
  background-color: ${MOBILE_RECIPE_NAV_BG_COLOR};
  border-bottom: thin solid ${MOBILE_RECIPE_NAV_BORDER_COLOR};
  width: 100%;
  padding-bottom: 25px;
  margin-bottom: 30px;
  .inner {
    width: 285px;
    margin: auto;
    a:hover {
      text-decoration: none;
    }
    a, a:visited {
      color: ${LINK_COLOR};
    }
    font-size: 13px;
    justify-content: center;
    &, &>*>* {
      display: flex;
    }
    &>*:not(:nth-child(2)) {
      width: 40%;
    }
    &>*:nth-child(2) {
      width: 20%;
      text-align: center;
      line-height: 1.8em;
    }
    &>*:first-child>* {
      float:left
    }
    &>*:last-child>* {
      float:right;
      direction: rtl;
    }
    .text, .current-page {
       line-height: 24px; 
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
`);

const OverviewPlotsWrapper = React.memo(styled.div`
  ${responsive({
  mobile: `
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 5px 15px;
      box-sizing: border-box;
    `
})};
`);

const NavLink = React.memo(({to, icon, text, className}) => (
  <div className={className}>
    <a href={`#/${to}`}>
      {icon} <div className='text'>{text}</div>
    </a>
  </div>
));

const PrevLink = React.memo(({to, text, className}) => (
  <NavLink to={to} icon={<ChevronLeft />} text={text} className={className} />
));

const NextLink = React.memo(({to, text, className}) => (
  <NavLink to={to} icon={<ChevronRight />} text={text} className={className} />
));

const Container = React.memo(styled.div`
  ${responsive({
  'phablet-desktop': `
    padding: ${PAGE_PADDING};
  `})};
`);

const TitleWrapper = React.memo(styled.div`
  width: 100%;
  ${responsive({
    'phablet-desktop': `
      text-align: left;
      padding: 0 10px;
      h1 {
          padding-left: 1px
      }
    `,
    mobile: `
      background-color: ${MOBILE_RECIPE_NAV_BG_COLOR};
      padding: 22px 10px;
      box-sizing: border-box;
      h1 {
        font-size: 17px;
        text-align: center;
        margin-bottom: 0;
      }
    `
  })}
`);

class CustomLocation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {mounted: false};
  }

  componentDidMount() {
    this.setState({mounted: true});
  }

  render() {
    const {children, history} = this.props;
    const {mounted} = this.state;
    if (mounted) {
      return (
        <LocationProvider history={history}>{children}</LocationProvider>
      )
    } else {
      return children({location: {}});
    }
  }
}

const channelLink = (recipe) =>
  `http://youtube.com/channel/${recipe.channelId}`;

const channelSchema = (recipe) => ({
  "@type": "Person",
  "name": recipe.channelTitle,
  "url": channelLink(recipe),
    "memberOf": {
    "@type": "WebSite",
    "name": "YouTube",
    "url": "http://youtube.com"
  }
});

const videoSchema = (topicTitle, keywords, recipe) => ({
    "@type": "VideoObject",
    "name": recipe.title,
    "keywords": keywords,
    "genre": 'http://vocab.getty.edu/aat/300027043',
    "description": `Video demonstrating how to make a recipe for ${topicTitle}`,
    "uploadDate": new Date(recipe.videoUploadDate).toISOString(),
    "author": channelSchema(recipe),
    "thumbnailUrl": `https://i.ytimg.com/vi/${recipe.videoId}/mqdefault.jpg`,
    "image": `https://i.ytimg.com/vi/${recipe.videoId}/maxresdefault.jpg`,
    "url": `https://youtube.com/watch?v=${recipe.videoId}`,
    "contentUrl": `https://youtube.com/watch?v=${recipe.videoId}`,
    "discussionUrl": `https://youtube.com/watch?v=${recipe.videoId}`
});

const recipeSchema = (topicTitle, keywords, recipe) => ({
  "@type": "Recipe",
  "author": channelSchema(recipe),
  "image": `https://i.ytimg.com/vi/${recipe.videoId}/maxresdefault.jpg`,
  "name": recipe.title,
  "description": `Recipe for ${topicTitle}`,
  "keywords": keywords,
  // "suitableForDiet": {
  //   "@type": "RestrictedDiet",
  //   "@id": categoryLink,
  //   "url": categoryLink
  // },
  // "description": recipe.summary.sections[0].paragraphs[0],
  "recipeIngredient": recipe.ingredients.map(ingr =>
    `${ingr.quantity} ${ingr.measure} of ${ingr.name}` ),
  "nutrition": {
    "@type": "NutritionInformation",
    "calories": `${recipe.calories} calories`
  },
  "recipeInstructions": [videoSchema(topicTitle, keywords, recipe)],
  "video": videoSchema(topicTitle, keywords, recipe)
});

const SEO = React.memo(({title, summary, recipes, datePublished, keywords}) => {
  const jsonLd = {
    "@context": "http://schema.org/",
    "@graph": [
      {
        "@type": "Collection",
        "name": capitalize(title),
        "headline": capitalize(title),
        "keywords": keywords,
        "description": `Comparison of ${recipes.length} recipes for ${title}`,
        "datePublished": new Date(datePublished).toISOString(),
        "hasPart": recipes.map(r => recipeSchema(title, keywords, r))
      }
    ]
  };
  return (
    <script type="application/ld+json">
      {JSON.stringify(jsonLd)}
    </script>
  );
});

const IngredientComparisonComponent = ({recipes, className}) => (
  <ArticleSection icon={listIcon} title='Ingredient comparison'
                  headerColor={HEADER_COLOR_INGREDIENT_COMPARISON}
                  className={className} openByDefault={true}>
    <table cellSpacing={0}>
      <thead>
        <tr className='header'>
          {recipes.map((r, i) => (
            <th key={i}><RecipeThumb recipe={r} className='thumb' /></th>
          ))}
        </tr>
      </thead>
      <tbody>
      <tr>
        {recipes.map((r, i) => (
          <td key={i}>
            {r.ingredients
              .map(ingr => ingr.name)
              .sort()
              .map(name => <div key={name}>{name}</div>)}
          </td>
        ))}
      </tr>
      </tbody>
    </table>
  </ArticleSection>
);

const IngredientComparison = styled(IngredientComparisonComponent)`
  width: 100%;
  margin-bottom: 40px;

  ${responsive({
    'mobile-phablet': `
      font-size: 13px;
    `,
    'phablet-desktop': `
      padding-bottom: 6px;
      table {
        margin-top: 2px;
      }
    `
  })};
  table {
    width: 100%;
  }
  .header th {
    padding: 3px 0;
    font-weight: normal;
    text-align: center
  }
  td {
    vertical-align: top;
    line-height: 1.45rem;
    text-align: center;
    
    div {
      padding: 3px 0;
    }
  }
`;

const RelatedArticlesComponent = ({className, articles}) => (
  <div className={className}>
    <h3>Related articles</h3>
    <ArticlesList articles={articles} />
  </div>
);

const RelatedArticles = React.memo(styled(RelatedArticlesComponent)`
  h3 {
    margin-bottom: 0.75em;
  }
  ${responsive({
    mobile: `
      margin: 20px auto;
      font-size: 13px;
    `,
    'phablet-desktop': `
      margin: 0 auto 10px auto;
      clear: both;
    `
  })};
  text-align: center;
`);

const UnpublishedWarningComponent = ({published, className}) => (
  published ? null :
  <Alert severity="warning" className={className}>
    This article draft is currently unpublished.
  </Alert>
)

const UnpublishedWarning = React.memo(styled(UnpublishedWarningComponent)`
  border: thin solid #f8e4d0;
  margin-bottom: 10px;

  & > * {
    vertical-align: middle;
  }

  ${responsive({
    mobile: `
        margin: 0 10px 15px 10px;
        .
      `,
    'phablet-desktop': `
        margin-right: 20px;
      `
  })};
`);

const Recipes = ({data, pageContext}) => {
  const {id, title, summary, recipes, baseFood, categories, relatedTopics,
    published, breadcrumbs, keywords, slug} = pageContext;
  const [recipeRefs, setRecipeRefs] = useState(recipes.map(r => React.createRef()));
  const servingSize = calculateServingSize(recipes, MAXIMUM_CALORIES);
  return (
    <CustomLocation history={history}>
      {({location}) => {
        const hash = location.pathname;
        const videoIds = recipes.map(r => r.videoId);
        const page = getPage(recipes, hash);
        if (page === 0 && hash && hash.length >= 1 && hash !== '/') {
          return <Redirect to='' noThrow />
        }
        return (
          <>
            <Helmet>
              <title>{capitalize(title)}</title>
              <meta name='keywords' content={keywords} />
              <meta name='description'
                    content={`Comparison of ${recipes.length} recipes for ${title}`} />
              <meta property="og:title" content={capitalize(title)} />
              <meta property="og:type" content='article' />
              <meta property="og:site_name" content='Recipe Watch' />
              <meta property="og:image"
                    content={`https://i.ytimg.com/vi/${recipes[0].videoId}/maxresdefault.jpg`} />
              <meta property="og:description"
                    content={`Comparison of ${recipes.length} recipes for ${title}`} />
              <meta property="og:image"
                    content={`${DOMAIN}/images/topics/${id}.jpg`} />
              <link rel="canonical" href={`${DOMAIN}/recipes/${slug}`} />
            </Helmet>
            <SEO {...pageContext} keywords={keywords} />
            <Container>
              <TitleWrapper>
                <UnpublishedWarning published={published} />
                <h1>{capitalize(title)}</h1>
              </TitleWrapper>
              <header className='mobile'>
                <MobileNavBar>
                  <div className='inner'>
                    {page > 0 ? (
                      <PrevLink to={page === 1 ? '' : videoIds[page - 2]}
                                text={page === 1 ? 'Overview' :
                                  `Recipe ${page - 1}`}/>
                    ) : <div/>}
                    <div className='current-page'>
                      {page === 0 ? 'Overview' : `Recipe ${page}`}
                    </div>
                    {page < recipes.length ? (
                      <NextLink to={videoIds[page]}
                                text={`Recipe ${page + 1}`}/>
                    ) : <div/>}
                  </div>
                </MobileNavBar>
              </header>

              <LargeScreenOrMatch path=''>
                <TopWrapper>
                  <TopLeftWrapper>
                    <Lead summary={summary} />
                  </TopLeftWrapper>
                  <Breadcrumbs categories={breadcrumbs} />
                </TopWrapper>
                <TopWrapper>
                  <NotSmallScreenOnly>
                    <TopLeftWrapper>
                      <Contents recipes={recipes} recipeRefs={recipeRefs} />
                      <IngredientComparison recipes={recipes} />
                    </TopLeftWrapper>
                  </NotSmallScreenOnly>
                  <OverviewPlotsWrapper>
                    <OverviewPlot
                      recipes={recipes}
                      baseFood={baseFood}
                      color={OVERVIEW_PLOT_COLORS[1]}
                      fields={['carbs', 'fat', 'protein']}
                    />
                    <OverviewPlot
                      recipes={recipes}
                      baseFood={baseFood}
                      color={OVERVIEW_PLOT_COLORS[2]}
                      fields={['sodium', 'cholesterol', 'total sugars']}
                    />
                  </OverviewPlotsWrapper>
                </TopWrapper>
              </LargeScreenOrMatch>
              <MainWrapper>
                <Main>
                  <div>
                    {recipes.map((r, i) => (
                      <LargeScreenOrMatch key={i} path={r.videoId}>
                        <Recipe
                          {...r}
                          {...{servingSize, published}}
                          recipeRef={recipeRefs[i]} />
                      </LargeScreenOrMatch>
                    ))}
                  </div>
                </Main>
                <DesktopSideBar className='tablet-desktop'/>
              </MainWrapper>
              {relatedTopics.length > 0 ?
                <RelatedArticles articles={relatedTopics} /> : null}
            </Container>
          </>
        )
      }}
    </CustomLocation>
  );
}

export default Recipes;
