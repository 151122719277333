import React from "react";
import styled from "styled-components";

const Heading = ({text, level}) => {
  if (level === 1) {
    return <h4>{text}</h4>
  }
  if (level === 2) {
    return <h5>{text}</h5>
  }
  return text;
};

const SectionBody = React.memo(({heading, level, paragraphs}) => (
  <>
    {heading ? <Heading text={heading} level={level} /> : null}
    {paragraphs.map((p, i) =>
      <div key={i} className='block-text'
           dangerouslySetInnerHTML={{__html: p}}/>
    )}
  </>
));

const Section = React.memo((props) => (
  <div className='section'>
    <SectionBody {...props} />
  </div>
));

const SummaryComponent = React.memo(({className, summary}) => (
  <div className={className}>
    {summary.sections.map((s, i) => {
      if (s.paragraphs.length === 0) {
        return <SectionBody key={i} {...s} />;
      } else {
        return <Section key={i} {...s} />;
      }
    })}
  </div>
));

const Summary = styled(SummaryComponent)`
  padding-left: 5px;
  padding-right: 4px;
  h4 {
    font-size: 1.25em;
    margin-top: 10px;
    margin-bottom: 0;
  }
  h5 {
    font-size: 1em;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  
  .section:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export default Summary;