import React, { PureComponent } from "react"

class HideOnError extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    else {
      return this.props.children;
    }
  }
}

export default HideOnError;
