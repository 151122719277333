import React, {PureComponent} from "react";
import styled from "styled-components";
import {BORDER_RADIUS, ICON_SIZE, responsive} from "../styles/dimens";
import {
  BORDER_COLOR,
  PAGE_BG_COLOR_MOBILE, SECTION_CHEVRON_COLOR,
  SECTION_HEADER_COLOR_MOBILE
} from "../styles/colors";
import {ChevronDown} from "styled-icons/fa-solid/ChevronDown";
import {ChevronRight} from "styled-icons/fa-solid/ChevronRight";
import {
  LargeScreenOrMatch,
  NotSmallScreen, NotSmallScreenOnly,
  SmallScreen,
  SmallScreenOnly
} from "./MediaQueries";
import {Link} from "@reach/router";
import * as PropTypes from "prop-types";
import Section from "./Section";

const SectionHeaderContainer = React.memo(styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  background-color: ${props => props.color};
  ${responsive({
  'phablet-desktop': `
      border-radius: ${BORDER_RADIUS-1}px ${BORDER_RADIUS-1}px 0 0;
      padding: 10px;
    `,
  mobile: `
      cursor: pointer;
      color: ${SECTION_HEADER_COLOR_MOBILE};
      background-color: transparent;
  `})};
  .icon {
    margin-right: 10px;
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    opacity: 0.9;
  }
  .text {
    line-height: ${ICON_SIZE}px;
    margin: 0 0 2px 0;
  }
`);

const SectionHeader = React.memo(({icon, text, color}) => (
  <SectionHeaderContainer color={color}>
    <img className='icon' src={icon} alt={text} width={ICON_SIZE} height={ICON_SIZE} />
    <h3 className='text'>{text}</h3>
  </SectionHeaderContainer>
));

class CollapsibleSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      opened: props.openByDefault,
      closed: false
    }
  }

  clicked(event) {
    const {opened, closed} = this.state;
    if (opened) {
      this.setState({opened: false, closed: true})
    } else {
      this.setState({opened: true, closed: false})
    }
  }

  render() {
    const {path, HeaderComponent, children, className} = this.props;

    const HeaderComponentWrapper = ({className}) => (
      <div className={className}>
        <HeaderComponent />
        <SmallScreenOnly>
          {this.state.opened ?
            <ChevronDown className='chevron' /> :
            <ChevronRight className='chevron' />}
        </SmallScreenOnly>
      </div>
    );

    const Header = styled(HeaderComponentWrapper)`
      display: flex;
      border-bottom: thin solid ${BORDER_COLOR};
      &>*:first-child {
          flex-grow: 1;
      }
      .chevron {
        color: ${SECTION_CHEVRON_COLOR};
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    `
    return (
      <div className={className} id={`/${path}`}>
        <SmallScreenOnly>
          <div onClick={this.clicked.bind(this)}>
            <Header />
          </div>
        </SmallScreenOnly>
        <NotSmallScreenOnly>
          <Header />
        </NotSmallScreenOnly>
        <>
          <SmallScreenOnly>
            <div style={{display: this.state.opened ? 'inherit' : 'none'}}>
              {children}
            </div>
          </SmallScreenOnly>
          <NotSmallScreenOnly>
            {children}
          </NotSmallScreenOnly>
        </>
      </div>
    )
  }
}

CollapsibleSection.propTypes = {
  openByDefault: PropTypes.bool
};

const ArticleSectionComponent = React.memo(({icon, title, path, headerColor,
                                       children, className, openByDefault=false}) => (
  <Section className={className}>
    <CollapsibleSection
      path={path}
      openByDefault={openByDefault}
      HeaderComponent={() => (<SectionHeader icon={icon} text={title}
                                             color={headerColor} />)}>
      <div className='content'>{children}</div>
    </CollapsibleSection>
  </Section>
));

const ArticleSection = React.memo(styled(ArticleSectionComponent)`
  box-sizing: border-box;
  ${responsive({
    mobile: `
      box-shadow: none;
      width: 100%;
      padding: 5px 20px;
      margin: 10px 0;
    `,
    'phablet-desktop': `
      margin: 10px;
      .content {
        padding: 10px;
      }
    `
  })};
`);

export default ArticleSection;