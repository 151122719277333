import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"
import * as PropTypes from "prop-types";
import {LINK_COLOR, MAIN_BLUE, PAGE_BG_COLOR_MOBILE} from "../styles/colors"
import {OVERVIEW_PLOTS_OUTER_WIDTH, responsive} from "../styles/dimens";
import Chip from "@material-ui/core/Chip";

const Container = React.memo(styled.div`
  ${responsive({
  'mobile': `
    margin: 15px 15px 25px 15px;
  `,
  'phablet-desktop': `
    width: ${OVERVIEW_PLOTS_OUTER_WIDTH + 40}px;
    padding: 0 10px;
  `
  })};
  box-sizing: border-box;
  flex: none;
  
  .bc {
    cursor: pointer;
    margin: 5px;
  }
  
  .bc.enabled {
    color: ${MAIN_BLUE};
    border-color: ${MAIN_BLUE};
  }
  
  .bc.enabled:hover {
    color: ${PAGE_BG_COLOR_MOBILE};
    background-color: ${MAIN_BLUE};
  }
  
  a:hover {
    text-decoration: none;
  }

  a, a:visited {
    color: ${LINK_COLOR};
  }
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: transparent;

  a:active {
    outline: none;
    border: none;
  }

  a:focus {
    outline: none;
  }
`);

const Breadcrumbs = React.memo(({categories}) => (
  <Container>
    {categories.map((c, i) => (
      c.active ?
        <Link to={c.path} key={i}>
          <Chip variant="outlined" className="bc enabled" label={c.text} />
        </Link> :
        <Chip key={i} variant="outlined" className="bc disabled" label={c.text} />
    ))}
  </Container>
));

Breadcrumbs.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Breadcrumbs;
