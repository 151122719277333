import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import styled from 'styled-components';
import {
  DESKTOP_LEFT_SECTION_WIDTH,
  DESKTOP_RIGHT_SECTION_WIDTH,
  DESKTOP_VIDEO_HEIGHT,
  MACRONUTRIENTS_SIZE,
  MOBILE_VIDEO_HEIGHT,
  MOBILE_VIDEO_WIDTH,
  responsive,
} from "../styles/dimens"
import {
  HEADER_COLOR_INGREDIENTS,
  HEADER_COLOR_MACRONUTRIENTS,
  HEADER_COLOR_SUMMARY, HEADER_COLOR_VIDEO,
} from "../styles/colors";
import youtubeIcon from "../../images/icons/youtube.svg";
import chartIcon from "../../images/icons/chart.svg";
import clipboardIcon from "../../images/icons/clipboard.svg";
import shoppingIcon from "../../images/icons/shopping.svg";
import { NotSmallScreenOnly, SmallScreenOnly } from "./MediaQueries";
import { AMAZON_AFFILIATE_ID } from "../constants";
// import loadable from '@loadable/component';

// const MacronutrientBreakdown = loadable(() => import("./MacronutrientBreakdown"));
import MacronutrientBreakdown from "./MacronutrientBreakdown";
import HideOnError from './HideOnError';
import Summary from "./Summary";
import ArticleSection from "./ArticleSection";
import NutritionFacts from "./NutritionFacts";
import RenderWhenVisible from "./RenderWhenVisible";

const VideoComponent = ({videoId, className}) => (
  <ArticleSection icon={youtubeIcon} title='Recipe video' path='video'
                  headerColor={HEADER_COLOR_VIDEO} className={className}
                  openByDefault={true}>
    <RenderWhenVisible>
      <iframe
        property='video'
        title='YouTube video'
        src={`https://www.youtube.com/embed/${videoId}`} frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen/>
    </RenderWhenVisible>
  </ArticleSection>
);

const Video = React.memo(styled(VideoComponent)`
  iframe {
    display: block;
  }
  
  ${responsive({
  'phablet-desktop': `
    width: ${DESKTOP_LEFT_SECTION_WIDTH};
    iframe { 
      box-sizing: border-box;
      width: 100%; 
      height: ${DESKTOP_VIDEO_HEIGHT}
    }
    .place-holder {
      height: ${DESKTOP_VIDEO_HEIGHT}
    }
  `
    ,
  mobile: `
    iframe {
      width: ${MOBILE_VIDEO_WIDTH};
      height: ${MOBILE_VIDEO_HEIGHT};
      margin: auto;
    }
    .place-holder {
      height: ${MOBILE_VIDEO_HEIGHT}
    }
    ` })}
  }
`);

const IngredientsComponent = React.memo(({items=[], published, className}) => (
  <ArticleSection icon={shoppingIcon} title='Ingredients' path='ingredients'
           headerColor={HEADER_COLOR_INGREDIENTS} className={className}>
      <table>
        <tbody>
        {items.map((ingredient, i) => (
          <tr key={i}>
            <td className='quantity' valign='top'>{ingredient.quantity}</td>
            <td>{' '}</td>
            <td className='measure' valign='top'>{ingredient.measure}</td>
            <td>{' '}</td>
            <td className='ingredient' valign='top'>
              {published && ingredient.affiliate ?
                <a target="_blank" rel='noopener'
                   href={`https://www.amazon.com/gp/search?ie=UTF8&tag=${AMAZON_AFFILIATE_ID}&linkCode=ur2&camp=1789&creative=9325&index=grocery&keywords=${ingredient.name}`}>
                  {ingredient.list_name}
                </a> : ingredient.list_name}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
  </ArticleSection>
));

const Ingredients = React.memo(styled(IngredientsComponent)`
  ${responsive({
    'phablet-desktop': `float: right; width: ${DESKTOP_RIGHT_SECTION_WIDTH};`})}
  table {
    margin: auto;
  }
  td {
    padding: 0 0 3px 1px;
  }
  .quantity {
    text-align: right;
    white-space: nowrap;
  }
  .measure {
    text-align: center;
    white-space: nowrap;
  }
  .ingredient {
    text-align: left;
  }
`);

class MacronutrientsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    }
  }

  render() {
    const { nutrients, className } = this.props;
    return (
      <ArticleSection icon={chartIcon} title='Macronutrient breakdown'
               headerColor={HEADER_COLOR_MACRONUTRIENTS}
               path='macronutrients' className={className}>
        <HideOnError>
          <RenderWhenVisible>
            <MacronutrientBreakdown nutrients={nutrients}/>
          </RenderWhenVisible>
        </HideOnError>
      </ArticleSection>
    );
  }
}

const Macronutrients = React.memo(styled(MacronutrientsComponent)`
  .place-holder {
    height: ${MACRONUTRIENTS_SIZE}px;
  }
  ${responsive({
    'phablet-desktop': `width: ${DESKTOP_LEFT_SECTION_WIDTH}`
  })}
`);

const RecipeSummaryComponent = React.memo(({className, summary}) => (
  <ArticleSection icon={clipboardIcon} title='Summary' path='summary'
           headerColor={HEADER_COLOR_SUMMARY} className={className}>
    <Summary summary={summary} />
  </ArticleSection>
));

const RecipeSummary = React.memo(styled(RecipeSummaryComponent)`
  display: block;
`);

const Title = ({videoId, title}) => (
  <>
    <SmallScreenOnly>
      <h2>{title}</h2>
    </SmallScreenOnly>
    <NotSmallScreenOnly>
      <h2 id={`/${videoId}`}>{title}</h2>
    </NotSmallScreenOnly>
  </>
);

const RecipeComponent = React.memo(({
    videoId, title, summary, ingredients, nutrients, nutritionFacts, calories,
    className, recipeRef, servingSize, mass, channelTitle, channelId, published}) => {
  const servings = servingSize ? mass / servingSize : 1;
  const ratio = 1 / servings;
  const selectedCalories = Math.round(ratio * calories);
  return (
    <div ref={recipeRef} className={className}>
      <Title videoId={videoId} title={title}/>
      <div className='channel'>
        by {' '}
        <a target='_blank' rel="noopener"
           href={`https://www.youtube.com/channel/${channelId}`}>
          {channelTitle}
        </a>
      </div>
      <SmallScreenOnly>
        <>
          <Video videoId={videoId}/>
          <Ingredients items={ingredients} published={published}/>
          <Macronutrients nutrients={nutrients}/>
          <NutritionFacts
            nutrients={nutritionFacts}
            calories={selectedCalories}
            servingSize={servingSize ? servingSize : mass}
            servings={servings}
          />
        </>
      </SmallScreenOnly>
      <NotSmallScreenOnly>
        <table cellPadding={0} cellSpacing={0}>
          <tbody>
          <tr>
            <td valign='top'>
              <Video videoId={videoId}/>
              <Macronutrients nutrients={nutrients}/>
            </td>
            <td valign='top'>
              <Ingredients items={ingredients} published={published}/>
              <NutritionFacts
                nutrients={nutritionFacts}
                calories={selectedCalories}
                servingSize={servingSize ? servingSize : mass}
                servings={servings}
              />
            </td>
          </tr>
          </tbody>
        </table>
      </NotSmallScreenOnly>
      <RecipeSummary summary={summary}/>
    </div>
  );
});

RecipeComponent.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.object.isRequired,
  published: PropTypes.bool.isRequired,
  ingredients: PropTypes.arrayOf(PropTypes.object).isRequired,
  nutritionFacts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default React.memo(styled(RecipeComponent)`
  margin-bottom: 40px;
  h2 {
    ${responsive({
      mobile: `
        font-size: 15px;
        margin: 5px 0 10px 0;
        padding: 0 20px;
        overflow: hidden;
        line-height: 1.45em;
        height: 2.9em;
      `,
      'phablet-desktop' : `
        padding: 0 12px;
      `
    })};
  }
  
  .channel {
    margin-bottom: 20px;
    font-weight: 500;
    ${responsive({
      mobile: `
        padding: 0 20px;
      `,
      'phablet-desktop': `
        padding: 0 13px;
      `
    })};
  }
  
  h3 {
    margin: 30px 0 0 0;
  }
`);
