import React from "react";
import ArticleSection from "./ArticleSection";
import infoIcon from "../../images/icons/info.svg";
import {
  HEADER_COLOR_NUTRITION_FACTS, MAIN_BLUE,
  NUTRITION_FACTS_ROW_BORDER_COLOR, PAGE_FG_COLOR
} from "../styles/colors";
import styled from "styled-components";
import {DESKTOP_RIGHT_SECTION_WIDTH, responsive} from "../styles/dimens";
import {
  gramsToOz,
  scaleUnitsFromGrams,
  scaleUnitsFromMcg
} from "../utils/nutrs";

const NutritionFactsRow = React.memo(({nutrient, servings, className=''}) => (
  <div className={'row ' + className}>
    <div className='label'>{nutrient.name}</div>
    <div className='value'>{scaleUnitsFromMcg(nutrient.amount / servings)}</div>
    <div className='pct value'>
      {nutrient.dailyPct ? `${Math.round(nutrient.dailyPct / servings)}%` : ''}
    </div>
  </div>
));

const NutritionFactsComponent = React.memo(({
    nutrients, calories, className, servings, servingSize}) => {
  const primary = nutrients.filter(n => n.primary || n.macro);
  const secondary = nutrients.filter(n => !n.primary && !n.macro);

  return (
    <ArticleSection icon={infoIcon} title='Nutrition Facts' path='nutrition-facts'
                    headerColor={HEADER_COLOR_NUTRITION_FACTS} className={className}>
      <div className='nf-container'>
        <div className='servings'>
          {Math.round(servings)} serving{servings > 1 ? 's' : ''} per recipe
        </div>
        <div className='serving-size'>
          <div className='label'>
            Serving size: {' '}
          </div>
          <div className='grow' />
          <div className='value'>
            {`${gramsToOz(servingSize)} (${scaleUnitsFromGrams(servingSize)})`}
          </div>
        </div>
        <div className='divider'/>
        <div className='big row'>
          <div className='label'>Calories</div>
          <div className='calories'>{calories}</div>
        </div>
        <div className='row'>
          <div className='daily-value'>% Daily Value*</div>
        </div>
        {primary.map((nutrient, i) => (
          <div key={i}>
            <NutritionFactsRow nutrient={nutrient} servings={servings} />
            {(nutrient.childNutrients || []).map((child, i) => (
              <NutritionFactsRow key={i} className='child' nutrient={child} servings={servings} />
            ))}
          </div>
        ))}
        <div className='divider'/>
        {secondary.map((nutrient, i) => (
          <NutritionFactsRow key={i} className='vitamin' nutrient={nutrient}
                             servings={servings} />
        ))}
        <div className='row note'>
          * The % Daily Value (DV) tells you how much a nutrient in a serving of
          food contributes to a daily diet. 2,000 calories a day is used for
          general nutrition advice.
        </div>
      </div>
    </ArticleSection>
  );
});

const NutritionFacts = React.memo(styled(NutritionFactsComponent)`
  ${responsive({
  'phablet-desktop': `float: right; width: ${DESKTOP_RIGHT_SECTION_WIDTH};`
})};
  .grow {
    flex-grow: 1;
  }
  .servings {
  }
  .serving-size {
    font-weight: bold;
    display: flex;
    line-height: 1.65rem;
  }
  .row {
    line-height: 1.45rem;
    display: flex;
    font-size: 13px;
    font-weight: bold;
    border-bottom: thin solid ${NUTRITION_FACTS_ROW_BORDER_COLOR};
  }
  .big.row {
    border-bottom: 4px solid ${PAGE_FG_COLOR};
  }
  .child .label {
    font-weight: normal;
    padding-left: 16px;
  }
  .big .label {
    font-size: 1.5em;
    flex-grow: 1;
    padding-top: 0.5em;
    line-height: 1.25em;
  }
  .calories {
    font-size: 1.9em;
    padding-top: 0.2em;
    line-height: 1.25em;
  }
  .row .value {
    font-weight: normal;
    padding-left: 6px;
  }
  .pct.value, .daily-value, .big .label {
    flex-grow: 1;
  }
  .pct.value, .calories, .daily-value {
    text-align: right;
    font-weight: bold;
  }
  .daily-value {
    font-weight: bold;
    font-size: 0.85em;
    line-height: 1.25rem;
  }
  .divider {
    border-bottom: 7px solid ${PAGE_FG_COLOR};
  }
  .vitamin.row * {
    font-weight: normal;
  }
  .note {
    font-weight: 100;
    font-size: 0.67em;
    line-height: 1.33em;
    border-top: 4px solid ${PAGE_FG_COLOR};
    border-bottom: none;
    padding: 5px 0 0 0;
  }
`);

export default NutritionFacts;